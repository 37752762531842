import { convertDateToJapaneseDate } from "../../../sdk/src/digitalForm";
import * as validator from "../../validator";
import { FieldDomTemplate } from "../definition/element/FieldDomTemplate";
import { FieldDomElement } from "../FieldDomElement";
import { FieldElementTemplate } from "./FieldElementTemplate";

const isSequential = (data: number[]): boolean => {
    for (let i = 1, l = data.length; i < l; i++) {
        if (data[i - 1] + 1 !== data[i]) return false;
    }
    return true;
}

export interface PhoneNumber {
    first: string;
    middle: string;
    last: string;
}

const matchPhoneNumber = (regex: RegExp, value: string): PhoneNumber | null => {
    const matched = regex.exec(value);
    if (matched && matched.length === 4) {
        return {
            first: matched[1],
            middle: matched[2],
            last: matched[3]
        };
    }
    return null;
};

const TemplateManager = {
    templates: {},
    getTemplates: function() { return this.templates; },
    getTemplate: function (name): FieldElementTemplate {
        const template = this.templates[name];
        if (template) {
            return Object.assign({ id: name }, template);
        }
        throw new Error("undefined field box template: " + name);
    },
    addTemplate: function (name, data) {
        this.templates[name] = data;
    },
    addTemplates: function (definitions) {
        for (let i in definitions) {
            if (!definitions.hasOwnProperty(i)) continue;
            this.templates[i] = definitions[i];
        }
    },
    startsWithJapaneseMultibyteCharacter: function (str) {
        return /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]/.exec(str);
    },
    isKatakanaString: function (str) {
        if (typeof str !== "string") {
            return false;
        }
        return /^[　ァ-ヶー]+$/.exec(str);
    },
    isNumberString: function (str) {
        if (typeof str !== "string") {
            return false;
        }
        return /^[0-9０-９]+$/.exec(str);
    },
    createValidatorStartWithJapaneseCharacter: function () {
        const self = this;
        return function () {
            const errors = [];
            if (!self.startsWithJapaneseMultibyteCharacter(JSON.parse(this.value))) {
                errors.push("正しく入力してください");
            }
            return errors;
        };
    },
    Util: {
        startsWithJapaneseMultibyteCharacter: function (str) {
            return /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]/.exec(str);
        },
        isKatakanaString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[　ァ-ヶー]+$/.exec(str);
        },
        isHiraganaString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[ぁ-んー]+$/.exec(str);
        },
        isNumberString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[0-9０-９]+$/.exec(str);
        },
        isNumberStringHankaku: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[0-9]+$/.exec(str);
        },
        isHankakuEisu: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[ 0-9a-zA-Z]+$/.exec(str);
        },
        isZenkakuEiji: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[　ａ-ｚＡ-Ｚ]+$/.exec(str);
        },
        isHankakuSuji: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[0-9]+$/.exec(str);
        },
        isZenkaku: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[^ -~｡-ﾟ]+$/.exec(str);
        },
        isEMailString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+$/.exec(str);
        },
        isEMailDomainString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(str);
        },
        //START New Validators
        isHiraganaStringWithSpace: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[　 ぁ-んー]+$/.exec(str);
        },
        isKatakanaStringWithSpace: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[　 ァ-ヶー]+$/.exec(str);
        },
        containsAnySpaces: function (str) {
            return /[　 ]/g.exec(str);
        },
        createValidatorKatakanaNoSpace: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isKatakanaStringWithSpace(value)) {
                    if (label) {
                        errors.push(`${label}を全角カタカナで入力してください`);
                    } else {
                        errors.push(`全角カタカナで入力してください`);
                    }
                }
                if(self.containsAnySpaces(value)){
                    errors.push(`空白を含めないでください`);
                }
                return errors;
            };
        },
        createValidatorKatakanaWithSpace: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isKatakanaStringWithSpace(value)) {
                    if (label) {
                        errors.push(`${label}を全角カタカナで入力してください`);
                    } else {
                        errors.push(`全角カタカナで入力してください`);
                    }
                }
                return errors;
            };
        },
        createValidatorHiraganaNoSpace: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isHiraganaStringWithSpace(value)) {
                    if(label){
                        errors.push(`${label}をひらがなで入力してください`);
                    } else {
                        errors.push(`ひらがなで入力してください`);
                    }
                }
                if(self.containsAnySpaces(value)){
                    errors.push(`空白を含めないでください`);
                }
                return errors;
            };
        },
        createValidatorHiraganaWithSpace: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isHiraganaStringWithSpace(value)) {
                    if(label){
                        errors.push(`${label}をひらがなで入力してください`);
                    } else {
                        errors.push(`ひらがなで入力してください`);
                    }
                }
                return errors;
            };
        },
        ////END New Validators
        createValidatorStartWithJapaneseCharacter: function () {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.startsWithJapaneseMultibyteCharacter(value)) {
                    errors.push("正しく入力してください");
                }
                return errors;
            };
        },
        createValidatorNotEmptyStartWithJapaneseCharacter: function (label) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!value) {
                    errors.push(`${label}を入力してください`)
                }
                if (!self.startsWithJapaneseMultibyteCharacter(value)) {
                    errors.push(`${label}を正しく入力してください`);
                }
                return errors;
            };
        },
        createValidatorNotEmptyStart: function (label) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!value) {
                    errors.push(`${label}を入力してください`)
                }
                return errors;
            };
        },
        createValidatorKatakana: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isKatakanaString(value)) {
                    if (label) {
                        errors.push(`${label}を全角カタカナで入力してください`);
                    } else {
                        errors.push(`全角カタカナで入力してください`);
                    }
                }
                return errors;
            };
        },
        createValidatorHiragana: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isHiraganaString(value)) {
                    if(label){
                        errors.push(`${label}をひらがなで入力してください`);
                    } else {
                        errors.push(`ひらがなで入力してください`);
                    }
                }
                return errors;
            };
        },
        createValidatorZenkaku: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isZenkaku(value)) {
                    errors.push(`全角で入力してください`);
                }
                return errors;
            };
        },
        createValidatorHankaku: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isHankakuEisu(value)) {
                    errors.push(`半角英数字で入力してください`);
                }
                return errors;
            };
        },
        createValidatorZenkakuEiji: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isZenkakuEiji(value)) {
                    errors.push(`全角英字で入力してください`);
                }
                return errors;
            };
        },
        createValidatorDigit: function (label = "", length = 0) {
            return this.createValidatorDigitPattern(label, length === 0 ? [] : [length]);
        },
        createValidatorDigitPattern: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isNumberString(value) || (pattern.length !== 0 && pattern.indexOf(value.length) === -1)) {
                    if (label && pattern.length !== 0) {
                        errors.push(`${label}${pattern.join("ケタまたは")}ケタを数字で入力してください`);
                    } else if (label) {
                        errors.push(`${label}を数字で入力してください`);
                    } else {
                        if (pattern.length > 0) {
                            if (pattern.length > 1 && isSequential(pattern)) {
                                errors.push(`${pattern[0]}ケタから${pattern[pattern.length - 1]}ケタの数字で入力してください`);
                            } else {
                                errors.push(`${pattern.join("ケタまたは")}ケタの数字で入力してください`);
                            }
                        } else {
                            errors.push(`数字で入力してください`);
                        }
                    }
                }
                return errors;
            };
        },
        createValidatorDigitPatternHankaku: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isNumberStringHankaku(value) || (pattern.length !== 0 && pattern.indexOf(value.length) === -1)) {
                    if (label && pattern.length !== 0) {
                        errors.push(`${label}${pattern.join("ケタまたは")}ケタを数字で入力してください`);
                    } else if (label) {
                        errors.push(`${label}を数字で入力してください`);
                    } else {
                        if (pattern.length > 0) {
                            if (pattern.length > 1 && isSequential(pattern)) {
                                errors.push(`${pattern[0]}ケタから${pattern[pattern.length - 1]}ケタの半角数字で入力してください`);
                            } else {
                                errors.push(`${pattern.join("ケタまたは")}ケタの半角数字で入力してください`);
                            }
                        } else {
                            errors.push(`半角数字で入力してください`);
                        }
                    }
                }
                return errors;
            };
        },
        createValidatorDigitPatternHankakuEisuji: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isHankakuEisu(value) || (pattern.length !== 0 && pattern.indexOf(value.length) === -1)) {
                    if (label && pattern.length !== 0) {
                        errors.push(`${label}${pattern.join("ケタまたは")}ケタの半角英数字で入力してください`);
                    } else if (label) {
                        errors.push(`${label}を半角英数字で入力してください`);
                    } else {
                        if (pattern.length > 0) {
                            if (pattern.length > 1 && isSequential(pattern)) {
                                errors.push(`${pattern[0]}ケタから${pattern[pattern.length - 1]}ケタの半角英数字で入力してください`);
                            } else {
                                errors.push(`${pattern.join("ケタまたは")}ケタの半角英数字で入力してください`);
                            }
                        } else {
                            errors.push(`半角英数字で入力してください`);
                        }
                    }
                }
                return errors;
            };
        },
        createValidatorDigitPatternHankakuSuji: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isHankakuSuji(value) || (pattern.length !== 0 && pattern.indexOf(value.length) === -1)) {
                    if (label && pattern.length !== 0) {
                        errors.push(`${label}${pattern.join("ケタまたは")}ケタの半角数字で入力してください`);
                    } else if (label) {
                        errors.push(`${label}を半角数字で入力してください`);
                    } else {
                        if (pattern.length > 0) {
                            if (pattern.length > 1 && isSequential(pattern)) {
                                errors.push(`${pattern[0]}ケタから${pattern[pattern.length - 1]}ケタの半角数字で入力してください`);
                            } else {
                                errors.push(`${pattern.join("ケタまたは")}ケタの半角数字で入力してください`);
                            }
                        } else {
                            errors.push(`半角数字で入力してください`);
                        }
                    }
                }
                return errors;
            };
        },
        createValidatorDigitLimitZenkaku: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isZenkaku(value) || value.length > pattern[0]){
                    errors.push(`全角${pattern[0]}文字以内で入力してください。`);
                }
                return errors;
            };
        },
        createValidatorDigitLimitZenkakuKatakana: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isZenkaku(value) || value.length > pattern[0] || !this.isKatakanaString(value)){
                    errors.push(`全角カタカナの${pattern[0]}文字以内で入力してください。`);
                }
                return errors;
            };
        },
        createValidatorDigitLimitHankakuEisuji: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isHankakuEisu(value) || value.length > pattern[0]){
                    errors.push(`半角英字${pattern[0]}文字以内で入力してください。`);
                }
                return errors;
            };
        },
        createValidatorDigitLimitZenkakuEiji: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isZenkakuEiji(value) || value.length > pattern[0]){
                    errors.push(`全角英字${pattern[0]}文字以内で入力してください。`);
                }
                return errors;
            };
        },
        createValidatorEMail: function () {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isEMailString(value)) {
                    errors.push(`正しく入力してください。`);
                }
                return errors;
            };
        },
        createValidatorEMailDomain: function () {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isEMailDomainString(value)) {
                    errors.push(`ドメインを正しく入力してください。`);
                }
                return errors;
            };
        },
        convertToASCIIDigit(str) {
            const charCodeOffset = "０".charCodeAt(0) - "0".charCodeAt(0);
            return str.replace(/[０-９]/g, (s) => {
                return String.fromCharCode(s.charCodeAt(0) - charCodeOffset);
            });
        },
        parsePhoneNumber: (value: string): PhoneNumber | null => {
            if (!value) return null;
            const patterns = [
                /^\((\d\d+)\)-?(\d\d+)-(\d\d\d\d)$/,
                /^(\d\d\d)-(\d\d\d\d)-(\d\d\d\d)$/,
                /^(\d\d\d)-(\d\d\d)-(\d\d\d\d)$/
            ];
            for(let i = 0; i < patterns.length; i++) {
                const result = matchPhoneNumber(patterns[i], value);
                if (result) return result;
            }
            return null;
        }
    }
};

export const TemplateUtil = TemplateManager.Util;

export const PhoneNumberValidator: validator.ValidatorDefinition<PhoneNumber> = {
    properties: {
        first: TemplateManager.Util.createValidatorDigitPattern(null, [2, 3, 4]),
        middle: TemplateManager.Util.createValidatorDigitPattern(null, [2, 3, 4]),
        last: TemplateManager.Util.createValidatorDigitPattern(null, [4])
    },
    all: (value: PhoneNumber) => {
        return [10, 11].includes(value.first.length + value.middle.length + value.last.length) ? [] : ["10ケタから11ケタの数字で入力してください"];
    }
};

const serializeName = (element: FieldDomElement): string => {
    const familyName = <HTMLInputElement>element.subInput("family_name");
    const firstName = <HTMLInputElement>element.subInput("first_name");
    return familyName.value + "　" + firstName.value;
}

const deserializeName = (element: FieldDomElement, value: string) => {
    const token = !value ? [] : value.split("　");
    (<HTMLInputElement>element.subInput("family_name")).value = token[0] || "";
    (<HTMLInputElement>element.subInput("first_name")).value = token[1] || "";
};

const setupNameTemplate = (element: FieldDomElement, definition) => {
    const context = definition.context;
    if (context?.placeholder) {
        const placeholder = context.placeholder;
        if (placeholder?.first_name) {
            (<HTMLInputElement>element.subInput("first_name")).placeholder = placeholder.first_name;
        }
        if (placeholder?.family_name) {
            (<HTMLInputElement>element.subInput("family_name")).placeholder = placeholder.family_name;
        }
    }
    if (context?.label) {
        const label = context.label;
        if (label?.first_name) {
            const labelElement = element.element.querySelector<HTMLLabelElement>(`[data-for="first_name"]`);
            if (labelElement) {
                labelElement.textContent = label.first_name;
            }
        }         
        if (label?.family_name) {
            const labelElement = element.element.querySelector<HTMLLabelElement>(`[data-for="family_name"]`);
            if (labelElement) {
                labelElement.textContent = label.family_name;
            }
        }
    }
};

const standardTemplates: { [s: string]: FieldElementTemplate } = {
    name: {
        kind: "dom",
        domTemplate: "input_name",
        value: serializeName,
        setValue: deserializeName,
        setup: setupNameTemplate,
        validatorSetup: (element: FieldDomElement, definition: FieldDomTemplate) => {
            const labelFamilyName = definition?.context?.label?.family_name;
            const labelFirstName = definition?.context?.label?.first_name;
            const type = definition?.context?.type || "kanji";
            switch (type) {
                case "kanji":
                    return {
                        family_name: TemplateUtil.createValidatorNotEmptyStartWithJapaneseCharacter(labelFamilyName || "姓"),
                        first_name: TemplateUtil.createValidatorNotEmptyStartWithJapaneseCharacter(labelFirstName || "名")
                    }
                case "kanji_alphabet":
                    return {
                        family_name: TemplateUtil.createValidatorNotEmptyStart(labelFamilyName || "姓"),
                        first_name: TemplateUtil.createValidatorNotEmptyStart(labelFirstName || "名")
                    }
                case "katakana":
                    return {
                        family_name: TemplateUtil.createValidatorKatakana(labelFamilyName || "セイ"),
                        first_name: TemplateUtil.createValidatorKatakana(labelFirstName || "メイ")
                    }
                case "hiragana":
                    return {
                        family_name: TemplateUtil.createValidatorHiragana(labelFamilyName || "姓"),
                        first_name: TemplateUtil.createValidatorHiragana(labelFirstName || "名")
                    }
            }
            throw new Error(`invalid name template type: ${type}`);
        }
    },
    name_kanji_alphabet: {
        kind: "dom",
        domTemplate: "input_name_kanji_alphabet",
        value: serializeName,
        setValue: deserializeName,
        setup: setupNameTemplate,
        validate: {
        }
    },
    name_kana: {
        kind: "dom",
        domTemplate: "input_name_kana",
        value: serializeName,
        setValue: deserializeName,
        setup: setupNameTemplate,
        validate: {
            family_name: TemplateUtil.createValidatorKatakana("セイ"),
            first_name: TemplateUtil.createValidatorKatakana("メイ")
        }
    },
    name_hiragana: {
        kind: "dom",
        domTemplate: "input_name_hiragana",
        value: serializeName,
        setValue: deserializeName,
        setup: setupNameTemplate,
        validate: {
            family_name: TemplateUtil.createValidatorHiragana("姓"),
            first_name: TemplateUtil.createValidatorHiragana("名")
        }
    },
    email: {
        kind: "dom",
        domTemplate: "input_email",
        value: function (element: FieldDomElement) {
            return element.subInput("user").value + "@" + element.subInput("domain").value;
        },
        setValue: (element: FieldDomElement, value: string) => {
            const token = !value ? [] : value.split("@");
            (<HTMLInputElement>element.subInput("user")).value = token[0] || "";
            (<HTMLInputElement>element.subInput("domain")).value = token[1] || "";
        },
        validate: {
            "user": TemplateUtil.createValidatorEMail(),
            "domain": TemplateUtil.createValidatorEMailDomain()
        }
    },
    age: {
        kind: "dom",
        domTemplate: "input_age",
        value: function (element: FieldDomElement) {
            return element.subInput("age").value;
        },
        validate: {
            age: TemplateUtil.createValidatorDigit()
        }
    },
    zipcode: {
        kind: "zipcode",
        id: "zipcode"
    },
    gender: {
        kind: "radio",
        id: "gender",
        options: [
            {
                "label": "男",
                "value": "男"
            },
            {
                "label": "女",
                "value": "女"
            }
        ]
    },
    gender_optional: {
        kind: "radio",
        id: "gender_optional",
        options: [
            {
                "label": "男",
                "value": "男"
            },
            {
                "label": "女",
                "value": "女"
            },
            {
                "label": "回答しない",
                "value": "回答しない"
            }
        ]
    },
    word: {
        kind: "dom",
        domTemplate: "input_word",
    },
    address: {
        kind: "dom",
        domTemplate: "input_address",
        validate: {
            address: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    address2: {
        kind: "dom",
        domTemplate: "input_address",
        validate: {
            address: TemplateManager.Util.createValidatorZenkaku()
        }
    },
    address_kana: {
        kind: "dom",
        domTemplate: "input_address_kana",
        validate: (element: FieldDomElement) => {
            const errors: string[] = [];
            if (!TemplateUtil.isKatakanaString(element.value)) {
                errors.push("全角カタカナで入力してください");
            }
            return errors;
        }
    },
    building_info: {
        kind: "dom",
        domTemplate: "input_building_info",
        validate: {
            building_info: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    phone_number: {
        kind: "dom",
        domTemplate: "input_phone_number",
        value: (element: FieldDomElement) => {
            const value1 = element.subInput("(XXX)___-____").value;
            const value2 = element.subInput("(___)XXX-____").value;
            const value3 = element.subInput("(___)___-XXXX").value;
            if (value1 || value2 || value3) {
                const list = [];
                if (value1) list.push(`(${value1})`);
                if (value2) list.push(value2);
                if (value3) list.push(value3);
                return list.join("-");
            }
            return "";
        },
        setValue: (element: FieldDomElement, value: string) => {
            const result = /^\((.*)\)-(.*)-(.*)$/.exec(value);
            if (result) {
                element.subInput("(XXX)___-____").value = result[1];
                element.subInput("(___)XXX-____").value = result[2];
                element.subInput("(___)___-XXXX").value = result[3];
            }
        },
        validate: {
            "(XXX)___-____": TemplateUtil.createValidatorDigit(),
            "(___)XXX-____": TemplateUtil.createValidatorDigit(),
            "(___)___-XXXX": TemplateUtil.createValidatorDigit()
        }
    },
    local_phone_number: {
        kind: "dom",
        domTemplate: "input_local_phone_number",
        value: (element: FieldDomElement) => {
            const value1 = element.subInput("XXX-____").value;
            const value2 = element.subInput("___-XXXX").value;
            if (value1 || value2) {
                return `${value1}-${value2}`;
            }
            return "";
        },
        validate: {
            "XXX-____": TemplateUtil.createValidatorDigit(),
            "___-XXXX": TemplateUtil.createValidatorDigit()
        }
    },
    birthdate: {
        kind: "dom",
        domTemplate: "input_birthdate",
        // @ts-ignore
        value: (element: FieldDomElement) => {
            return {
                year: TemplateUtil.convertToASCIIDigit(element.subInput("year").value),
                month: TemplateUtil.convertToASCIIDigit(element.subInput("month").value),
                date: TemplateUtil.convertToASCIIDigit(element.subInput("date").value)
            };
        },
        validate: {
            year: function (value) {
                const errors = [];
                const year = JSON.parse(this.value).year;
                if (!isFinite(year) || year < 2000) errors.push("正しい年を入力してください");
                return errors;
            },
            month: function (value) {
                const errors = [];
                const month = JSON.parse(this.value).month;
                if (!isFinite(month) || month <= 0 || month > 12) errors.push("正しい月を入力してください");
                return errors;
            },
            date: function (value) {
                const errors = [];
                const date = JSON.parse(this.value).date;
                if (!isFinite(date) || date <= 0 || date > 31) errors.push("正しい日を入力してください");
                return errors;
            }
        }
    },
    relation: {
        kind: "dom",
        domTemplate: "input_relation",
        validate: {
            relation: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    map: {
        kind: "dom",
        domTemplate: "input_map",
        manualUpdate: (element: FieldDomElement) => {
            element.subInput("select_button").addEventListener("click", () => {
                element.update();
            });
        },
        setup: (element: FieldDomElement) => {
            const center = { lat: 34.694782, lng: 135.195507 };
            // @ts-ignore
            if (!window.google) {
                console.error("failed to load google map module");
                return;
            }
            // @ts-ignore
            const map = new google.maps.Map(element.subInput("map"), {
                center: center,
                zoom: 15,
                clickableIcons: false,
                mapTypeControl: false,
                streetViewControl: false
            });
            map.addListener("click", (e) => {
                // @ts-ignore
                element.marker.setPosition(e.latLng);
            });
            map.setOptions({
                styles: [
                    { featureType: 'poi', stylers: [{ visibility: 'on' }] },
                    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
                    { featureType: 'administrative', stylers: [{ visibility: 'on' }] },
                    { featureType: 'road', stylers: [{ visibility: 'simplified' }] }
                ]
            });
            // @ts-ignore
            element.mapObject = map;
            // @ts-ignore
            element.marker = new google.maps.Marker({
                position: center,
                map: map
            });
        },
        // @ts-ignore
        value: (element: FieldDomElement) => {
            const center = { lat: 34.694782, lng: 135.195507 };
            let data = {
                center,
                zoom: 15,
                marker: center
            };
            // @ts-ignore
            if (window.google) {
                data = {
                    // @ts-ignore
                    center: element.mapObject.getCenter(),
                    // @ts-ignore
                    zoom: element.mapObject.getZoom(),
                    // @ts-ignore
                    marker: element.marker.position
                };
            }
            return data;
        }
    }
};

TemplateManager.addTemplates(standardTemplates);

function option(label: string, value?: string): HTMLOptionElement {
    const option = document.createElement("option");
    if (value !== undefined) {
        option.value = value;
    }
    option.textContent = label;
    return option;
}

function updateYears(element: FieldDomElement, eras) {
    const gengo = <HTMLSelectElement>element.subInput("gengo");
    const year = <HTMLSelectElement>element.subInput("year");
    let value = parseInt(year.value);
    const eraIndex = gengo.selectedIndex + (Array.from(gengo.querySelectorAll("option")).length === 1 ? 1 : 0);
    year.innerHTML = "";
    year.appendChild(option("--", ""));
    if (eraIndex >= 1) {
        const min = eras[eraIndex - 1].min || 1;
        const max = eras[eraIndex - 1].max;
        for (let j = min; j <= max; j++) {
            const o = option(
                j === 1 ? "元" : String(j), String(j)
            );
            o.selected = j === 1;
            year.appendChild(o);
        }
        if (isNaN(value) || value < min || value > max) {
            year.selectedIndex = 0;
        } else {
            year.value = String(value > max ? 1 : value);
        }
    }
    dispatchOnChange(year);
}

const updateDates = (element: FieldDomElement) => {
    const year = <HTMLSelectElement>element.subInput("year");
    const month = <HTMLSelectElement>element.subInput("month");
    const date = <HTMLSelectElement>element.subInput("date");
    const currentDate = parseInt(date.value);
    const numberOfDays = month.value === "2" ? 29 : ["4", "6", "9", "11"].includes(month.value) ? 30 : 31;
    const options = [`<option value="">--</option>`];
    for (let i = 0; i < numberOfDays; i++) {
        options.push(`<option value="${i + 1}">${i + 1}</option>`);
    }
    date.innerHTML = options.join("");
    if (isNaN(currentDate)) {
        date.selectedIndex = 0;
    } else {
        date.value = String(Math.min(currentDate, numberOfDays));
    }
    dispatchOnChange(date);
}

const selectLabel = (select: HTMLSelectElement, label: string) => {
    for (let i = 0, l = select.options.length; i < l; i++) {
        const option = select.options[i];
        if (option.textContent === label) {
            select.selectedIndex = i;
            dispatchOnChange(select);
        }
    }
};

const getGengo = (select: HTMLSelectElement): string | null => {
    if (select.selectedIndex >= 0) {
        const option = select.options[select.selectedIndex];
        // 元号未選択(--)と値が空文字列の西暦を区別するため
        return select.selectedIndex === 0 && option.textContent == "--" ? null : option.value;
    }
    return "";
}

const updateGengoYear = (gengoYear: HTMLInputElement, gengo: HTMLSelectElement, year: HTMLSelectElement) => {
    gengoYear.value = (getGengo(gengo) || "") + year.value;
    const e = document.createEvent("HTMLEvents");
    e.initEvent("change", false, true);
    gengoYear.dispatchEvent(e);
};

const updateFullDate = (full, year, month, date) => {
    if(year.value && month.value){
        if (date.getAttribute("data-date-disabled") === "true") {
            full.value = `${year.value}年${month.value}月`;
        } else {
            full.value = `${year.value}年${month.value}月${date.value}日`;
        }
    } else if(month.value && date.value){
        full.value = `${month.value}月${date.value}日`;
    } else {
        return;
    }
    const e = document.createEvent("HTMLEvents");
    e.initEvent("change", false, true);
    full.dispatchEvent(e);
};

const dispatchOnChange = (element: HTMLElement) => {
    const e = document.createEvent("HTMLEvents");
    e.initEvent("change", false, true);
    element.dispatchEvent(e);
};

const getSelectedLabel = (select: HTMLSelectElement) => {
    if (select.disabled) return undefined;
    if (select.selectedIndex >= 0) {
        return select.options[select.selectedIndex].textContent;
    }
    return "";
};

export interface JapaneseCalendarDate {
    gengo: string;
    year: string;
    month: string;
    date: string;
}

const setValueJapaneseCalendarObject = (element: FieldDomElement, value: JapaneseCalendarDate) => {
    const gengo = <HTMLSelectElement>element.subInput("gengo");
    const year = <HTMLSelectElement>element.subInput("year");
    const month = <HTMLSelectElement>element.subInput("month");
    const date = <HTMLSelectElement>element.subInput("date");
    if (value) {
        selectLabel(gengo, value.gengo);
        updateGengoYear(<HTMLInputElement>element.subInput("gengo_year"), gengo, year);
        selectLabel(year, value.year === "1" ? "元" : value.year);
        selectLabel(month, value.month);
        selectLabel(date, value.date);
        updateFullDate(element.subInput("full"),year,month,date);
    }
}

const predefinedEra = [
  {
    "name": "西暦",
    "value": "AD",
    "min": 1900,
    "max": 2020
  },
  {
    "name": "大正",
    "value": "T",
    "max": 15
  },
  {
    "name": "昭和",
    "value": "S",
    "max": 64
  },
  {
    "name": "平成",
    "value": "H",
    "max": 31
  },
  {
    "name": "令和",
    "value": "R",
    "max": (new Date()).getFullYear() - 2018
  }
];

const japaneseCalendarToday = (): JapaneseCalendarDate => {
    const japaneseDate = convertDateToJapaneseDate(new Date());
    return japaneseDate;
};

const setValueJapaneseCalendar = (element: FieldDomElement, value: any) => {
    if (typeof value === "object") {
        setValueJapaneseCalendarObject(element, value);
    } else if (typeof value === "string") {
        if (value === "today") {
            setValueJapaneseCalendarObject(element, japaneseCalendarToday());
        } else {
            const token = value.split(",");
            setValueJapaneseCalendarObject(element, {
                gengo: token[0],
                year: token[1],
                month: token[2],
                date: token[3]
            })
        }
    }
}

TemplateManager.addTemplate("date_in_japanese_calendar", {
    kind: "dom",
    domTemplate: "input_date_in_japanese_calendar",
    value: (element: FieldDomElement) => {
        return {
            gengo: getSelectedLabel(<HTMLSelectElement>element.subInput("gengo")),
            gengo_year: element.subInput("gengo_year").value,
            yearNumber: parseInt(element.subInput("year").value),
            year: getSelectedLabel(<HTMLSelectElement>element.subInput("year")),
            month: getSelectedLabel(<HTMLSelectElement>element.subInput("month")),
            date: getSelectedLabel(<HTMLSelectElement>element.subInput("date")),
            full: element.subInput("full").value,
        };
    },
    setValue: setValueJapaneseCalendar,
    setup: (element: FieldDomElement, definition) => {
        const gengo = <HTMLSelectElement>element.subInput("gengo");
        const year = <HTMLSelectElement>element.subInput("year");
        const month = <HTMLSelectElement>element.subInput("month");
        const date = <HTMLSelectElement>element.subInput("date");
        const gengoYear = <HTMLInputElement>element.subInput("gengo_year");
        const full = <HTMLInputElement>element.subInput("full");
        const dateType = definition.context?.dateType;
        if (definition.context?.withoutDate || dateType === "withoutDate") {
            date.disabled = true;
            date.setAttribute("data-date-disabled", "true");
            Array.from(element.element.querySelectorAll<HTMLElement>(`[data-group="date"]`)).forEach(e => e.style.display = "none");
        }
        else if (dateType) {
            switch(dateType) {
                case "withoutGengoYear":
                    gengo.disabled = true;
                    year.disabled = true;
                    gengo.setAttribute("data-gengo-disabled", "true");
                    year.setAttribute("data-year-disabled", "true");
                    Array.from(element.element.querySelectorAll<HTMLElement>(`[data-name="gengo"]`)).forEach(e => e.style.display = "none");
                    Array.from(element.element.querySelectorAll<HTMLElement>(`[data-name="year"]`)).forEach(e => e.style.display = "none");
                    Array.from(element.element.querySelectorAll<HTMLElement>(`[data-name="year"]+label`)).forEach(e => e.style.display = "none");
                    break;
                case "onlyGengoYear":
                    date.disabled = true;
                    month.disabled = true;
                    date.setAttribute("data-date-disabled", "true");
                    month.setAttribute("data-month-disabled", "true");
                    Array.from(element.element.querySelectorAll<HTMLElement>(`[data-group="date"]`)).forEach(e => e.style.display = "none");
                    Array.from(element.element.querySelectorAll<HTMLElement>(`[data-name="month"]`)).forEach(e => e.style.display = "none");
                    Array.from(element.element.querySelectorAll<HTMLElement>(`[data-name="month"]+label`)).forEach(e => e.style.display = "none");
                    break;
                default:
                    throw new Error(`unknown date type: ${dateType}`);
            }
        }
        const eras = (definition.context || { eras: [] }).eras || predefinedEra;
        if (eras.length > 1) {
            gengo.appendChild(option("--", ""));
        }
        for (let i = 0, l = eras.length; i < l; i++) {
            gengo.appendChild(option(eras[i].name, eras[i].value));
        }
        gengo.addEventListener("change", () => {
            updateYears(element, eras);
            updateGengoYear(gengoYear, gengo, year);
            updateFullDate(full,year,month,date);
        });
        year.addEventListener("change", () => {
            updateGengoYear(gengoYear, gengo, year);
            updateFullDate(full,year,month,date);
        });
        month.addEventListener("change", () => {
            updateDates(element);
            updateFullDate(full,year,month,date);
        });
        date.addEventListener("change", () => {
            updateFullDate(full,year,month,date);
        });
        month.appendChild(option("--", ""));
        for (let i = 0; i < 12; i++) {
            month.appendChild(option(String(i + 1), String(i + 1)));
        }
        date.appendChild(option("--", ""))
        updateYears(element, eras);
        if (definition.context?.initialValue) {
            setValueJapaneseCalendar(element, definition.context.initialValue);
        } else {
            gengo.selectedIndex = 0;
            year.value = "";
            month.value = "";
            date.value = "";
            full.value="";
            updateGengoYear(gengoYear, gengo, year);
            updateFullDate(full,year,month,date);
        }
    },
    validate: {
        gengo: function (value, element: FieldDomElement) {
            if (element.subInput("gengo").getAttribute("data-gengo-disabled") === "true") return [];
            const errorMessage = "元号を選択してください";
            const selectElement = element.subInput("gengo") as HTMLSelectElement;
            return getGengo(selectElement) === null ? [errorMessage] : [];
        },
        year: function (value, element: FieldDomElement) {
            if (element.subInput("year").getAttribute("data-year-disabled") === "true") return [];
            return value === "" ? ["年を選択してください"] : [];
        },
        month: function (value, element: FieldDomElement) {
            if (element.subInput("month").getAttribute("data-month-disabled") === "true") return [];
            return value === "" ? ["月を選択してください"] : [];
        },
        date: function (value: string, element: FieldDomElement) {
            if (element.subInput("date").getAttribute("data-date-disabled") === "true") return [];
            return value === "" ? ["日を選択してください"] : [];
        }
    }
});

/**
 * @deprecated
 */
TemplateManager.addTemplate("year_month_in_japanese_calendar", {
    kind: "dom",
    domTemplate: "input_year_month_in_japanese_calendar",
    value: (element: FieldDomElement) => {
        return {
            gengo: getSelectedLabel(<HTMLSelectElement>element.subInput("gengo")),
            gengo_year: element.subInput("gengo_year").value,
            year: getSelectedLabel(<HTMLSelectElement>element.subInput("year")),
            month: getSelectedLabel(<HTMLSelectElement>element.subInput("month")),
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        const gengo = <HTMLSelectElement>element.subInput("gengo");
        const year = <HTMLSelectElement>element.subInput("year");
        const month = <HTMLSelectElement>element.subInput("month");
        if (value) {
            selectLabel(gengo, value.gengo);
            updateGengoYear(<HTMLInputElement>element.subInput("gengo_year"), gengo, year);
            selectLabel(year, value.year);
            selectLabel(month, value.month);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const gengo = <HTMLSelectElement>element.subInput("gengo");
        const year = <HTMLSelectElement>element.subInput("year");
        const month = <HTMLSelectElement>element.subInput("month");
        const gengoYear = <HTMLInputElement>element.subInput("gengo_year");
        const eras = definition.context.eras;
        // gengo.appendChild(option("--", ""));
        if (eras.length > 1) {
            gengo.appendChild(option("--", ""));
        }
        for (let i = 0, l = eras.length; i < l; i++) {
            gengo.appendChild(option(eras[i].name, eras[i].value));
        }
        // updateGengoYear(gengoYear, gengo, year);
        gengo.addEventListener("change", () => {
            updateYears(element, eras);
            updateGengoYear(gengoYear, gengo, year);
        });
        year.addEventListener("change", () => {
            updateGengoYear(gengoYear, gengo, year);
        });
        month.appendChild(option("--", ""));
        for (let i = 0; i < 12; i++) {
            month.appendChild(option(String(i + 1), String(i + 1)));
        }
        gengo.selectedIndex = 0;
        year.value = "";//definition.context.initialValue.year;
        month.value = "";//definition.context.initialValue.month;
        updateYears(element, eras);
        updateGengoYear(gengoYear, gengo, year);
    },
    validate: {
        gengo: function (value) {
            return value === "" ? ["元号を選択してください"] : [];
        },
        year: function (value) {
            return value === "" ? ["年を選択してください"] : [];
        },
        month: function (value) {
            return value === "" ? ["月を選択してください"] : [];
        }
    }
});

TemplateManager.addTemplate("pregnancy_week", {
    kind: "dom",
    domTemplate: "input_pregnancy_week",
    value: (element: FieldDomElement) => {
        return {
            weeks: (<HTMLInputElement>element.subInput("weeks")).value,
            days: (<HTMLInputElement>element.subInput("days")).value,
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        const weeks = <HTMLSelectElement>element.subInput("weeks");
        const days = <HTMLSelectElement>element.subInput("days");
        if (value) {
            weeks.value = value.weeks;
            days.value = value.days;
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const weeks = <HTMLSelectElement>element.subInput("weeks");
        const days = <HTMLSelectElement>element.subInput("days");
        weeks.value = "";
        days.value = "";
    },
    validate: {
        weeks: TemplateUtil.createValidatorDigit(),
        days: TemplateUtil.createValidatorDigit(),
    }
});

TemplateManager.addTemplate("time", {
    kind: "dom",
    domTemplate: "input_time",
    value: (element: FieldDomElement) => {
        return {
            ampm: getSelectedLabel(<HTMLSelectElement>element.subInput("ampm")),
            hour: getSelectedLabel(<HTMLSelectElement>element.subInput("hour")),
            minute: getSelectedLabel(<HTMLSelectElement>element.subInput("minute")),
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        const ampm = <HTMLSelectElement>element.subInput("ampm");
        const hour = <HTMLSelectElement>element.subInput("hour");
        const minute = <HTMLSelectElement>element.subInput("minute");
        if (value) {
            selectLabel(ampm, value.ampm);
            selectLabel(hour, value.hour);
            selectLabel(minute, value.minute);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const hour = <HTMLSelectElement>element.subInput("hour");
        const minute = <HTMLSelectElement>element.subInput("minute");
        hour.appendChild(option("--", ""));
        for (let i = 0, l = 12; i < l; i++) {
            hour.appendChild(option(String(i), String(i)));
        }
        minute.appendChild(option("--", ""));
        for (let i = 0, l = 60; i < l; i++) {
            minute.appendChild(option(String(i), String(i)));
        }
        hour.value = "";//definition.context.initialValue.gengo;
        minute.value = "";//definition.context.initialValue.year;
    },
    validate: {
        ampm: function (value) {
            return value === "" ? ["午前午後を選択してください"] : [];
        },
        hour: function (value) {
            return value === "" ? ["時間を選択してください"] : [];
        },
        minute: function (value) {
            return value === "" ? ["分を選択してください"] : [];
        },
    }
});

TemplateManager.addTemplate("common_time", {
    kind: "dom",
    domTemplate: "input_common_time",
    value: (element: FieldDomElement) => {
        return {
            hour: getSelectedLabel(<HTMLSelectElement>element.subInput("hour")),
            minute: getSelectedLabel(<HTMLSelectElement>element.subInput("minute")),
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        const hour = <HTMLSelectElement>element.subInput("hour");
        const minute = <HTMLSelectElement>element.subInput("minute");
        if (value) {
            selectLabel(hour, value.hour);
            selectLabel(minute, value.minute);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const hour = <HTMLSelectElement>element.subInput("hour");
        const minute = <HTMLSelectElement>element.subInput("minute");
        const twentyfour = definition.context?.twentyfour;
        hour.appendChild(option("--", ""));
        const hours = twentyfour ? 24 : 12; 
        for (let i = 0, l = hours; i < l; i++) {
            hour.appendChild(option(String(i), String(i)));
        }
        if (definition.context?.withoutMinute) {
            minute.disabled = true;
            minute.setAttribute("data-minute-disabled", "true");
            Array.from(element.element.querySelectorAll<HTMLElement>(`[data-group="minute"]`)).forEach(e => e.style.display = "none");
        }
        minute.appendChild(option("--", ""));
        for (let i = 0, l = 60; i < l; i++) {
            minute.appendChild(option(String(i), String(i)));
        }
        hour.value = "";//definition.context.initialValue.gengo;
        minute.value = "";//definition.context.initialValue.year;
        const prefix = element.element.querySelector("label.prefix");
        prefix.textContent = definition.prefix;
        const suffix = element.element.querySelector("label.suffix");
        suffix.textContent = definition.suffix;
    },
    validate: {
        hour: function (value) {
            return value === "" ? ["時間を選択してください"] : [];
        },
        minute: function (value, element) {
            if (element.subInput("minute").getAttribute("data-minute-disabled") === "true") return [];
            return value === "" ? ["分を選択してください"] : [];
        },
    }
});

const updateFullDate2 = (full, years, months) => {
    full.value="";
    if(years.value && years.value > 0){
        full.value += `${years.value}年`;
    }
    if(months.value && months.value > 0){
        full.value += `${months.value}月`;
    }
    if(months.value === "0" && years.value === "0" ){
        full.value = " ";
    }
    const e = document.createEvent("HTMLEvents");
    e.initEvent("change", false, true);
    full.dispatchEvent(e);
};

interface SimpleYearMonthValue {
    years: string;
    months: string;
}

const setValueSimpleYearMonth = (element: FieldDomElement, value: SimpleYearMonthValue) => {
    const years = <HTMLSelectElement>element.subInput("years");
    const months = <HTMLSelectElement>element.subInput("months");
    if (value) {
        years.value = value.years;
        months.value = value.months;
        updateFullDate2(element.subInput("full"), years, months);
    }
}

TemplateManager.addTemplate("simple_year_month", {
    kind: "dom",
    domTemplate: "input_simple_year_month",
    value: (element: FieldDomElement) => {
        return {
            years: (<HTMLInputElement>element.subInput("years")).value,
            months: (<HTMLInputElement>element.subInput("months")).value,
            full: (<HTMLInputElement>element.subInput("full")).value
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        if (typeof value === "string") {
            const token = value.split(",");
            setValueSimpleYearMonth(element, {
                years: token[0],
                months: token[1]
            });
        } else {
            setValueSimpleYearMonth(element, value);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const years = <HTMLSelectElement>element.subInput("years");
        const months = <HTMLSelectElement>element.subInput("months");
        const full = <HTMLInputElement>element.subInput("full");
        years.value = "";
        months.value = "";
        years.addEventListener("change", () => {
            updateFullDate2(full,years,months);
        });
        months.addEventListener("change", () => {
            updateFullDate2(full,years,months);
        });
        updateFullDate2(full,years,months);
    },
    validate: {
        years: function (value) {
            return value === "" ? ["年を選択してください"] : [];
        },
        months: function (value) {
            return value === "" ? ["月を選択してください"] : [];
        },
        full: function (value){
            return value === " " ? ["正しい年または月を選択してください"] : [];
        }
    }
});

interface SelectCountyValue {
    akasatana: string;
    country: string;
}

const setValueSelectCountry = (element: FieldDomElement, value: SelectCountyValue) => {
    const akasatana = <HTMLSelectElement>element.subInput("akasatana");
    const country = <HTMLSelectElement>element.subInput("country");
    if (value) {
        selectLabel(akasatana, value.akasatana);
        selectLabel(country, value.country);
    }
}


TemplateManager.addTemplate("select_country", {
    kind: "dom",
    domTemplate: "input_select_country",
    value: (element: FieldDomElement) => {
        return {
            akasatana: getSelectedLabel(<HTMLSelectElement>element.subInput("akasatana")),
            country: getSelectedLabel(<HTMLSelectElement>element.subInput("country"))
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        if (typeof value === "string") {
            const token = value.split(",");
            setValueSelectCountry(element, {
                akasatana: token[0],
                country: token[1]
            });
        } else {
            setValueSelectCountry(element, value);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const excludeStatelessness = definition.context?.excludeStatelessness;
        const akasatana = <HTMLSelectElement>element.subInput("akasatana");
        const country = <HTMLSelectElement>element.subInput("country");
        const statelessnessOption = akasatana.querySelector<HTMLOptionElement>(`option[value="無国籍"]`);
        if (excludeStatelessness) {
            statelessnessOption.parentElement.removeChild(statelessnessOption);
        }
        // updateGengoYear(gengoYear, gengo, year);
        // updateFullDate(full,year,month,date);
        akasatana.addEventListener("change", () => {
            updateCountry(element, excludeStatelessness);
        });
        akasatana.value = "";//definition.context.initialValue.gengo;
        country.value = "";//definition.context.initialValue.year;
        updateCountry(element, excludeStatelessness);
    },
    validate: {
        akasatana: function (value) {
            return value === "" ? ["選択してください"] : [];
        },
        country: function (value) {
            return value === "" ? ["国名を選択してください"] : [];
        }
    }
});

function updateCountry(element: FieldDomElement, excludeStatelessness?: boolean) {
    const akasatana = <HTMLSelectElement>element.subInput("akasatana");
    const country = <HTMLSelectElement>element.subInput("country");
    country.innerHTML = "";
    
    switch(akasatana.value){
        case 'ア':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="ア">
            <option value="アフガニスタン">アフガニスタン</option>
            <option value="アルバニア">アルバニア</option>
            <option value="アルジェリア">アルジェリア</option>
            <option value="アルゼンチン">アルゼンチン</option>
            <option value="アラブ首長国連邦">アラブ首長国連邦</option>
            <option value="アイスランド">アイスランド</option>
            <option value="アイルランド">アイルランド</option>
            <option value="米国">アメリカ合衆国</option>
            <option value="アンゴラ">アンゴラ</option>
            <option value="アンティグア・バーブーダ">アンティグア・バーブーダ</option>
            <option value="アルメニア">アルメニア</option>
            <option value="アゼルバイジャン">アゼルバイジャン</option>
            <option value="アンドラ">アンドラ</option>
        </optgroup>
        <optgroup label="イ">
            <option value="インド">インド</option>
            <option value="インドネシア">インドネシア</option>
            <option value="イラン">イラン</option>
            <option value="イラク">イラク</option>
            <option value="イスラエル">イスラエル</option>
            <option value="イタリア">イタリア</option>
            <option value="英国">イギリス</option>
            <option value="イエメン">イエメン</option>
        </optgroup>
        <optgroup label="ウ">
            <option value="ウガンダ">ウガンダ</option>
            <option value="ウルグアイ">ウルグアイ</option>
            <option value="ウクライナ">ウクライナ</option>
            <option value="ウズベキスタン">ウズベキスタン</option>
        </optgroup>
        <optgroup label="エ">
            <option value="エクアドル">エクアドル</option>
            <option value="エルサルバドル">エルサルバドル</option>
            <option value="エチオピア">エチオピア</option>
            <option value="エストニア">エストニア</option>
            <option value="エリトリア">エリトリア</option>
            <option value="エスワティニ">エスワティニ</option>
            <option value="エジプト">エジプト</option>
            <option value="英国">英国</option>
        </optgroup>
        <optgroup label="オ">
            <option value="オーストラリア">オーストラリア</option>
            <option value="オーストリア">オーストリア</option>
            <option value="オマーン">オマーン</option>
            <option value="オランダ">オランダ</option>
        </optgroup>
            `;
            break;
        case 'カ':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="カ">
            <option value="カンボジア">カンボジア</option>
            <option value="カメルーン">カメルーン</option>
            <option value="カナダ">カナダ</option>
            <option value="カーボベルデ">カーボベルデ</option>
            <option value="ガボン">ガボン</option>
            <option value="ガーナ">ガーナ</option>
            <option value="ガンビア">ガンビア</option>
            <option value="ガイアナ">ガイアナ</option>
            <option value="韓国">韓国</option>
            <option value="カザフスタン">カザフスタン</option>
            <option value="カタール">カタール</option>
        </optgroup>
        <optgroup label="キ">
            <option value="キューバ">キューバ</option>
            <option value="キプロス">キプロス</option>
            <option value="ギリシャ">ギリシャ</option>
            <option value="ギニア">ギニア</option>
            <option value="ギニアビサウ">ギニアビサウ</option>
            <option value="キリバス">キリバス</option>
            <option value="キルギス">キルギス</option>
            <option value="北マケドニア">北マケドニア</option>
        </optgroup>
        <optgroup label="ク">
            <option value="クロアチア">クロアチア</option>
            <option value="グアテマラ">グアテマラ</option>
            <option value="クウェート">クウェート</option>
            <option value="グレナダ">グレナダ</option>
        </optgroup>
        <optgroup label="ケ">
            <option value="ケニア">ケニア</option>
        </optgroup>
        <optgroup label="コ">
            <option value="コロンビア">コロンビア</option>
            <option value="コンゴ共和国">コンゴ共和国</option>
            <option value="コンゴ民主共和国">コンゴ民主共和国</option>
            <option value="コスタリカ">コスタリカ</option>
            <option value="コモロ">コモロ</option>
            <option value="コートジボワール">コートジボワール</option>
            <option value="コソボ">コソボ</option>
        </optgroup>
            `;
            break;
        case 'サ':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="サ">
            <option value="サンマリノ">サンマリノ</option>
            <option value="サウジアラビア">サウジアラビア</option>
            <option value="サントメ・プリンシペ">サントメ・プリンシペ</option>
            <option value="サモア">サモア</option>
            <option value="ザンビア">ザンビア</option>
        </optgroup>
        <optgroup label="シ">
            <option value="ジブチ">ジブチ</option>
            <option value="ジャマイカ">ジャマイカ</option>
            <option value="シエラレオネ">シエラレオネ</option>
            <option value="シリア">シリア</option>
            <option value="シンガポール">シンガポール</option>
            <option value="ジンバブエ">ジンバブエ</option>
            <option value="ジョージア">ジョージア</option>
        </optgroup>
        <optgroup label="ス">
            <option value="スリランカ">スリランカ</option>
            <option value="スペイン">スペイン</option>
            <option value="スーダン">スーダン</option>
            <option value="スウェーデン">スウェーデン</option>
            <option value="スイス">スイス</option>
            <option value="スリナム">スリナム</option>
            <option value="スロベニア">スロベニア</option>
            <option value="スロバキア">スロバキア</option>
        </optgroup>
        <optgroup label="セ">
            <option value="赤道ギニア">赤道ギニア</option>
            <option value="セネガル">セネガル</option>
            <option value="セーシェル">セーシェル</option>
            <option value="セントルシア">セントルシア</option>
            <option value="セントビンセント">セントビンセント</option>
            <option value="セントクリストファー・ネービス">セントクリストファー・ネービス</option>
            <option value="セルビア・モンテネグロ">セルビア・モンテネグロ</option>
            <option value="セルビア">セルビア</option>
        </optgroup>
        <optgroup label="ソ">
            <option value="ソマリア">ソマリア</option>
            <option value="ソロモン">ソロモン</option>
        </optgroup>
            `;
            break;
        case 'タ':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="タ">
            <option value="台湾">台湾</option>
            <option value="タイ">タイ</option>
            <option value="タンザニア">タンザニア</option>
            <option value="タジキスタン">タジキスタン</option>
        </optgroup>
        <optgroup label="チ">
            <option value="中央アフリカ">中央アフリカ</option>
            <option value="チャド">チャド</option>
            <option value="チリ">チリ</option>
            <option value="中国">中国</option>
            <option value="チェコ">チェコ</option>
            <option value="朝鮮">朝鮮</option>
            <option value="チュニジア">チュニジア</option>
        </optgroup>
        <optgroup label="ツ">
            <option value="ツバル">ツバル</option>
        </optgroup>
        <optgroup label="テ">
            <option value="デンマーク">デンマーク</option>
        </optgroup>
        <optgroup label="ト">
            <option value="ドミニカ共和国">ドミニカ共和国</option>
            <option value="ドミニカ">ドミニカ</option>
            <option value="ドイツ">ドイツ</option>
            <option value="トーゴ">トーゴ</option>
            <option value="トリニダード・トバゴ">トリニダード・トバゴ</option>
            <option value="トルコ">トルコ</option>
            <option value="トンガ">トンガ</option>
            <option value="トルクメニスタン">トルクメニスタン</option>
        </optgroup>
            `;
            break;
        case 'ナ':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="ナ">
            <option value="ナイジェリア">ナイジェリア</option>
            <option value="ナウル">ナウル</option>
            <option value="ナミビア">ナミビア</option>
        </optgroup>
        <optgroup label="ニ">
            <option value="ニュージーランド">ニュージーランド</option>
            <option value="ニカラグア">ニカラグア</option>
            <option value="ニジェール">ニジェール</option>
        </optgroup>
        <optgroup label="ネ">
            <option value="ネパール">ネパール</option>
        </optgroup>
        <optgroup label="ノ">
            <option value="ノルウェー">ノルウェー</option>
        </optgroup>
            `;
            break;
        case 'ハ':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="ハ">
            <option value="バーレーン">バーレーン</option>
            <option value="バルバドス">バルバドス</option>
            <option value="バングラデシュ">バングラデシュ</option>
            <option value="バハマ">バハマ</option>
            <option value="ハイチ">ハイチ</option>
            <option value="ハンガリー">ハンガリー</option>
            <option value="パキスタン">パキスタン</option>
            <option value="パナマ">パナマ</option>
            <option value="パラグアイ">パラグアイ</option>
            <option value="パプアニューギニア">パプアニューギニア</option>
            <option value="パラオ">パラオ</option>
            <option value="バチカン">バチカン</option>
            <option value="バヌアツ">バヌアツ</option>
            <option value="パレスチナ">パレスチナ</option>
        </optgroup>
        <optgroup label="ヒ">
            <option value="東ティモール">東ティモール</option>
        </optgroup>
        <optgroup label="フ">
            <option value="ブラジル">ブラジル</option>
            <option value="ブルガリア">ブルガリア</option>
            <option value="ブータン">ブータン</option>
            <option value="ブルンジ">ブルンジ</option>
            <option value="ブルネイ">ブルネイ</option>
            <option value="フィンランド">フィンランド</option>
            <option value="フランス">フランス</option>
            <option value="フィジー">フィジー</option>
            <option value="フィリピン">フィリピン</option>
            <option value="ブルキナファソ">ブルキナファソ</option>
        </optgroup>
        <optgroup label="ヘ">
            <option value="ベルギー">ベルギー</option>
            <option value="ベリーズ">ベリーズ</option>
            <option value="ベラルーシ">ベラルーシ</option>
            <option value="ベナン">ベナン</option>
            <option value="ペルー">ペルー</option>
            <option value="米国">米国</option>
            <option value="ベネズエラ">ベネズエラ</option>
            <option value="ベトナム">ベトナム</option>
        </optgroup>
        <optgroup label="ホ">
            <option value="ボリビア">ボリビア</option>
            <option value="ボツワナ">ボツワナ</option>
            <option value="ホンジュラス">ホンジュラス</option>
            <option value="ポーランド">ポーランド</option>
            <option value="ポルトガル">ポルトガル</option>
            <option value="ボスニア・ヘルツェゴビナ">ボスニア・ヘルツェゴビナ</option>
        </optgroup>
            `;
            break;
        case 'マ':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="マ">
            <option value="マダガスカル">マダガスカル</option>
            <option value="マレーシア">マレーシア</option>
            <option value="マリ">マリ</option>
            <option value="マラウイ">マラウイ</option>
            <option value="マルタ">マルタ</option>
            <option value="マーシャル">マーシャル</option>
        </optgroup>
        <optgroup label="ミ">
            <option value="ミャンマー">ミャンマー</option>
            <option value="ミクロネシア">ミクロネシア</option>
            <option value="南アフリカ共和国">南アフリカ共和国</option>
            <option value="南スーダン共和国">南スーダン共和国</option>
        </optgroup>
        <optgroup label="メ">
            <option value="メキシコ">メキシコ</option>
        </optgroup>
        <optgroup label="モ">
            <option value="モーリタニア">モーリタニア</option>
            <option value="モナコ">モナコ</option>
            <option value="モンゴル">モンゴル</option>
            <option value="モロッコ">モロッコ</option>
            <option value="モルディブ">モルディブ</option>
            <option value="モーリシャス">モーリシャス</option>
            <option value="モザンビーク">モザンビーク</option>
            <option value="モルドバ">モルドバ</option>
            <option value="モンテネグロ">モンテネグロ</option>
        </optgroup>
            `;
            break;
        case 'ヤ':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="ユ">
            <option value="ユーゴースラヴィア">ユーゴースラヴィア</option>
        </optgroup>
        <optgroup label="ヨ">
            <option value="ヨルダン">ヨルダン</option>
        </optgroup>
            `;
            break;
        case 'ラ':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <optgroup label="ラ">
            <option value="ラオス">ラオス</option>
            <option value="ラトビア">ラトビア</option>
        </optgroup>
        <optgroup label="リ">
            <option value="リベリア">リベリア</option>
            <option value="リビア">リビア</option>
            <option value="リヒテンシュタイン">リヒテンシュタイン</option>
            <option value="リトアニア">リトアニア</option>
        </optgroup>
        <optgroup label="ル">
            <option value="ルクセンブルク">ルクセンブルク</option>
            <option value="ルーマニア">ルーマニア</option>
            <option value="ルワンダ">ルワンダ</option>
        </optgroup>
        <optgroup label="レ">
            <option value="レバノン">レバノン</option>
            <option value="レソト">レソト</option>
        </optgroup>
        <optgroup label="ロ">
            <option value="ロシア">ロシア</option>
        </optgroup>
            `;
            break;
        case '無国籍':
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            <option value="無国籍">無国籍</option>
            `;
            country.options[1].selected = true;
            break;
        default:
            country.innerHTML = 
            `
            <option value="" disabled>--</option>
            `;
            break;
    }
    country.selectedIndex = 0;
}

interface ZokugaraCodeValue {
    code_1: string;
    code_2: string;
    code_3: string;
    code_4: string;
}

const setValueZokugaraCode = (element: FieldDomElement, value: ZokugaraCodeValue) => {
    const code_1 = <HTMLSelectElement>element.subInput("code_1");
    const code_2 = <HTMLSelectElement>element.subInput("code_2");
    const code_3 = <HTMLSelectElement>element.subInput("code_3");
    const code_4 = <HTMLSelectElement>element.subInput("code_4");
    if (value) {
        selectLabel(code_1, value.code_1);
        selectLabel(code_2, value.code_2);
        selectLabel(code_3, value.code_3);
        selectLabel(code_4, value.code_4);
    }
}

TemplateManager.addTemplate("zokugara_code", {
    kind: "dom",
    domTemplate: "input_zokugara_code",
    value: (element: FieldDomElement) => {
        return {
            code_1: getSelectedLabel(<HTMLSelectElement>element.subInput("code_1")),
            code_2: getSelectedLabel(<HTMLSelectElement>element.subInput("code_2")),
            code_3: getSelectedLabel(<HTMLSelectElement>element.subInput("code_3")),
            code_4: getSelectedLabel(<HTMLSelectElement>element.subInput("code_4"))
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        if (typeof value === "string") {
            const token = value.split(",");
            setValueZokugaraCode(element, {
                code_1: token[0],
                code_2: token[1],
                code_3: token[2],
                code_4: token[3]
            });
        } else {
            setValueZokugaraCode(element, value);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const excludeStatelessness = definition.context?.excludeStatelessness;
        const code_1 = <HTMLSelectElement>element.subInput("code_1");
        const code_2 = <HTMLSelectElement>element.subInput("code_2");
        const code_3 = <HTMLSelectElement>element.subInput("code_3");
        const code_4 = <HTMLSelectElement>element.subInput("code_4");
        code_1.value = "";
        code_2.value = "";
        code_3.value = "";
        code_4.value = "";
        initZokugaraCode(element);
    },
    validate: {
        code_1: function (value) {
            return value === "" ? ["選択してください"] : [];
        },
        code_2: function (value) {
            return value === "" ? ["選択してください"] : [];
        },
        code_3: function (value) {
            return value === "" ? ["選択してください"] : [];
        },
        code_4: function (value) {
            return value === "" ? ["選択してください"] : [];
        }
    }
});

function initZokugaraCode(element: FieldDomElement){
    const code_1 = <HTMLSelectElement>element.subInput("code_1");
    const code_2 = <HTMLSelectElement>element.subInput("code_2");
    const code_3 = <HTMLSelectElement>element.subInput("code_3");
    const code_4 = <HTMLSelectElement>element.subInput("code_4");
    const options = 
    `
    <option value="">--</option>
    <option value="02:世帯主">02:世帯主</option>
    <option value="03:準世帯主">03:準世帯主</option>
    <option value="11:夫">11:夫</option>
    <option value="12:妻">12:妻</option>
    <option value="13:夫（未届）">13:夫（未届）</option>
    <option value="14:妻（未届）">14:妻（未届）</option>
    <option value="21:子（男）">21:子（男）</option>
    <option value="22:子（男）">22:子（男）</option>
    <option value="23:子（男）">23:子（男）</option>
    <option value="24:子（男）">24:子（男）</option>
    <option value="25:子（男）">25:子（男）</option>
    <option value="26:子（男）">26:子（男）</option>
    <option value="27:子（男）">27:子（男）</option>
    <option value="28:子（男）">28:子（男）</option>
    <option value="29:子（男）">29:子（男）</option>
    <option value="31:子（女）">31:子（女）</option>
    <option value="32:子（女）">32:子（女）</option>
    <option value="33:子（女）">33:子（女）</option>
    <option value="34:子（女）">34:子（女）</option>
    <option value="35:子（女）">35:子（女）</option>
    <option value="36:子（女）">36:子（女）</option>
    <option value="37:子（女）">37:子（女）</option>
    <option value="38:子（女）">38:子（女）</option>
    <option value="39:子（女）">39:子（女）</option>
    <option value="51:父">51:父</option>
    <option value="52:母">52:母</option>
    <option value="71:兄">71:兄</option>
    <option value="74:弟">74:弟</option>
    <option value="81:姉">81:姉</option>
    <option value="84:妹">84:妹</option>
    <option value="96:縁故者">96:縁故者</option>
    <option value="98:使用人">98:使用人</option>
    <option value="99:同居人">99:同居人</option>
    `;
    code_1.innerHTML =
    code_2.innerHTML =
    code_3.innerHTML =
    code_4.innerHTML =
    options;
}

TemplateManager.addTemplate("annotation", {
    kind: "dom",
    domTemplate: "input_annotation",
    value: (element: FieldDomElement) => {
        return {
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
    },
    setup: (element: FieldDomElement, definition) => {
        const context = definition.context;
        const annotation = element.element.querySelector("label.annotation");
        if(definition.context && context.annotation){
            annotation.textContent = context.annotation;
            if(context.style){
                const style = context.style;
                annotation.setAttribute("style",style);
            }
        }
    },
    validate: {
    }
});

export { TemplateManager };
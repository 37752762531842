export const formTemplates = {
  "visa": {
    "kind": "select",
    "title": "在留資格",
    "options": [
      {
        "label": "--",
        "value": ""
      },
      {
        "label": "教授",
        "value": "教授"
      },
      {
        "label": "芸術",
        "value": "芸術"
      },
      {
        "label": "宗教",
        "value": "宗教"
      },
      {
        "label": "報道",
        "value": "報道"
      },
      {
        "label": "投資・経営",
        "value": "投資・経営"
      },
      {
        "label": "法律・会計業務",
        "value": "法律・会計業務"
      },
      {
        "label": "医療",
        "value": "医療"
      },
      {
        "label": "研究",
        "value": "研究"
      },
      {
        "label": "教育",
        "value": "教育"
      },
      {
        "label": "技術",
        "value": "技術"
      },
      {
        "label": "人文知識・国際業務",
        "value": "人文知識・国際業務"
      },
      {
        "label": "企業内転勤",
        "value": "企業内転勤"
      },
      {
        "label": "技能",
        "value": "技能"
      },
      {
        "label": "技能実習1号イ",
        "value": "技能実習1号イ"
      },
      {
        "label": "技能実習1号ロ",
        "value": "技能実習1号ロ"
      },
      {
        "label": "技能実習2号イ",
        "value": "技能実習2号イ"
      },
      {
        "label": "技能実習2号ロ",
        "value": "技能実習2号ロ"
      },
      {
        "label": "技能実習3号イ",
        "value": "技能実習3号イ"
      },
      {
        "label": "技能実習3号ロ",
        "value": "技能実習3号ロ"
      },
      {
        "label": "経営・管理",
        "value": "経営・管理"
      },
      {
        "label": "技術・人文知識・国際業務",
        "value": "技術・人文知識・国際業務"
      },
      {
        "label": "介護",
        "value": "介護"
      },
      {
        "label": "文化活動",
        "value": "文化活動"
      },
      {
        "label": "留学",
        "value": "留学"
      },
      {
        "label": "研修",
        "value": "研修"
      },
      {
        "label": "家族滞在",
        "value": "家族滞在"
      },
      {
        "label": "特定活動",
        "value": "特定活動"
      },
      {
        "label": "特別永住者",
        "value": "特別永住者"
      },
      {
        "label": "日本人の配偶者等",
        "value": "日本人の配偶者等"
      },
      {
        "label": "永住者の配偶者等",
        "value": "永住者の配偶者等"
      },
      {
        "label": "定住者",
        "value": "定住者"
      },
      {
        "label": "特定技能1号",
        "value": "特定技能1号"
      },
      {
        "label": "特定技能2号",
        "value": "特定技能2号"
      },
      {
        "label": "在留の資格なし",
        "value": "在留の資格なし"
      },
      {
        "label": "高度専門職1号イ",
        "value": "高度専門職1号イ"
      },
      {
        "label": "高度専門職1号ロ",
        "value": "高度専門職1号ロ"
      },
      {
        "label": "高度専門職1号ハ",
        "value": "高度専門職1号ハ"
      },
      {
        "label": "高度専門職2号",
        "value": "高度専門職2号"
      },
      {
        "label": "永住者",
        "value": "永住者"
      },
      {
        "label": "未取得",
        "value": "未取得"
      },
      {
        "label": "仮滞在",
        "value": "仮滞在"
      },
      {
        "label": "一時庇護",
        "value": "一時庇護"
      }
    ]
  },
  "school_grade": {
    "title": "学年",
    "kind": "select",
    "options": [
      {
        "label": "--",
        "value": ""
      },
      {
        "label": "小学校1年生",
        "value": "小1"
      },
      {
        "label": "小学校2年生",
        "value": "小2"
      },
      {
        "label": "小学校3年生",
        "value": "小3"
      },
      {
        "label": "小学校4年生",
        "value": "小4"
      },
      {
        "label": "小学校5年生",
        "value": "小5"
      },
      {
        "label": "小学校6年生",
        "value": "小6"
      },
      {
        "label": "中学校1年生",
        "value": "中1"
      },
      {
        "label": "中学校2年生",
        "value": "中2"
      },
      {
        "label": "中学校3年生",
        "value": "中3"
      }
    ]
  },
  "hou_30_45": {
    "title": "法第30条45規定区分",
    "kind": "radio",
    "options": [
      {
        "label": "中長期在留者",
        "value": "中長期在留者"
      },
      {
        "label": "特別永住者",
        "value": "特別永住者"
      },
      {
        "label": "一時庇護許可者",
        "value": "一時庇護許可者"
      },
      {
        "label": "仮滞在許可者",
        "value": "仮滞在許可者"
      },
      {
        "label": "出生による経過滞在者",
        "value": "出生による経過滞在者"
      },
      {
        "label": "国籍喪失による経過滞在者",
        "value": "国籍喪失による経過滞在者"
      }
    ]
  },
  "foreigner_field": {
    "kind": "field",
    "fields": [
      {
        "kind": "radio",
        "title": "在留カードやパスポートを持っていますか。",
        "id": "has_card",
        "options": [
          {
            "label": "在留カード、特別永住者証明書",
            "value": "有 "
          },
          {
            "label": "パスポート（在留カードの代わり）",
            "value": "有"
          },
          {
            "label": "なし",
            "value": "無"
          },
          {
            "label": "職権",
            "value": "職権"
          }
        ],
        "required": true
      },
      {
        "id": "nationality",
        "title": "国籍",
        "description": "国名を選択してください。",
        "kind": "template",
        "template": "select_country"
      },
      {
        "id": "qualification",
        "title": "在留資格",
        "kind": "template",
        "template": "visa",
        "required": true
      },
      {
        "kind": "template",
        "id": "mover_stay",
        "template": "simple_year_month",
        "required": false,
        "title": "在留期間"
      },
      {
        "id": "residence_type",
        "kind": "template",
        "template": "hou_30_45",
        "required": true
      },
      {
        "kind": "text",
        "id": "mover_card",
        "title": "在留カード番号",
        "min": 12,
        "max": 12,
        "placeholder": "例：AB12345678CD",
        "validator": {
          "kind": "SpecifiedLengthDigitsOnlyHankakuEisuji",
          "pattern": [12]
        },
        "required": true
      },
      {
        "kind": "template",
        "id": "period",
        "title": "在留期間満了日",
        "template": "date_in_japanese_calendar",
        "required": true,
        "context": {
          "eras": [
            {
              "name": "西暦",
              "value": "A.D.",
              "min": 2000,
              "max": 2100
            }
          ]
        }
      }
    ]
  },
  "birthdate_universal_short": {
    "kind": "template",
    "title": "生年月日",
    "template": "date_in_japanese_calendar",
    "context": {
      "eras": [
        {
          "name": "明治",
          "value": "M",
          "max": 45
        },
        {
          "name": "大正",
          "value": "T",
          "max": 15
        },
        {
          "name": "昭和",
          "value": "S",
          "max": 64
        },
        {
          "name": "平成",
          "value": "H",
          "max": 31
        },
        {
          "name": "令和",
          "value": "R",
          "max": 7
        },
        {
          "name": "西暦",
          "value": "A.D.",
          "min": 1900,
          "max": 2025
        }
      ]
    }
  },
  "birthdate_gengo": {
    "kind": "template",
    "title": "生年月日",
    "template": "date_in_japanese_calendar",
    "context": {
      "eras": [
        {
          "name": "明治",
          "value": "明治",
          "max": 45
        },
        {
          "name": "大正",
          "value": "大正",
          "max": 15
        },
        {
          "name": "昭和",
          "value": "昭和",
          "max": 64
        },
        {
          "name": "平成",
          "value": "平成",
          "max": 31
        },
        {
          "name": "令和",
          "value": "令和",
          "max": 7
        }
      ]
    }
  },
  "address_set": {
    "kind": "array",
    "items": [
      {
        "id": "$(name)_zipcode",
        "kind": "template",
        "title": "郵便番号",
        "template": "zipcode",
        "required": true
      },
      {
        "kind": "template",
        "id": "$(name)_address",
        "title": "都道府県・市区町村・町名",
        "description":"かならず都道府県名から入力してください",
        "template": "address2",
        "refer": "$(name)_zipcode-raw",
        "validator":"ZenkakuOnly",
        "placeholder":"例：○○県○○市○○町"
      },
      {
        "kind":"text",
        "id":"$(name)_address_chouban",
        "title": "字名丁目・番地・号など",
        "validator":"ZenkakuOnly",
        "placeholder":"〇丁目〇-〇"
      },
      {
        "kind":"text",
        "id":"$(name)_address_building",
        "title": "建物名（方書）",
        "placeholder":"例：ヨコスカマンションA館3号棟201号室",
        "validator":{
          "kind":"LimitLengthOnlyZenkaku",
          "pattern":[100]
        }
      },
      {
        "kind":"text",
        "id":"target_$(name)_address",
        "hidden":true
      }
    ]
  },
  "honseki_set":{
    "kind": "field",
    "id": "$(admin_prefix)mover_honseki_field_$(order)",
    "title": "本籍",
    "fields": [
      {
        "id": "$(admin_prefix)mover_honseki_zipcode_$(order)",
        "kind": "template",
        "title": "郵便番号",
        "template": "zipcode",
        "required": true
      },
      {
        "kind": "template",
        "id": "$(admin_prefix)mover_honseki_address_$(order)",
        "title": "都道府県・市区町村・町名",
        "description":"$(address_description)",
        "template": "address2",
        "refer": "$(admin_prefix)mover_honseki_zipcode_$(order)-raw",
        "validator":"ZenkakuOnly",
        "placeholder":"$(address_placeholder)"
      },
      {
        "kind":"text",
        "id":"$(admin_prefix)mover_honseki_address_$(order)_chouban",
        "title": "丁目・番地など",
        "validator":"ZenkakuOnly",
        "placeholder":"$(chouban_placeholder)"
      }
    ]
  },
  "setai_admin":{
    "kind":"array",
    "items":[
      {
        "id": "admin_$(id_prefix)_setai_$(order)",
        "title": "$(order)人目の世帯員情報",
        "kind": "field",
        "foldable": true,
        "fields": [          
          {
            "id": "admin_$(id_prefix)_setai_name_$(order)",
            "kind": "text",
            "csvqr":true,
            "title": "$(title_prefix)住所世帯員-氏名",
            "required": true
          },
          {
            "id": "admin_$(id_prefix)_setai_atenabangou_$(order)",
            "kind": "text",
            "csvqr":true,
            "title": "$(title_prefix)住所世帯員-宛名番号",
            "required": true,
            "validator": {
              "kind": "SpecifiedLengthDigitsOnlyHankakuEisuji",
              "pattern": [10]
            }
          },
          {
            "id": "admin_$(id_prefix)_setai_relation_$(order)",
            "kind": "text",
            "csvqr":true,
            "title": "$(title_prefix)住所世帯員-新続柄"
          },
          {
            "id": "$(id_prefix)_setai_zokugara_code_$(order)",
            "kind": "template",
            "template":"zokugara_code",
            "csvqr":true,
            "title": "$(title_prefix)住所世帯員-続柄コード",
            "required": true
          }
        ]
      }
    ]
  },
  "admin_revision_field_wrap":{
    "kind":"field",
    "title":"通称記載・削除その$(num)",
    "id":"oldname_field_$(num)",
    "foldable":true,
    "fields":[
      {
        "id": "admin_mover_revision_name_$(order)_$(num)",
        "kind": "text",
        "csvqr":true,
        "title":"異動者通称名漢字",
        "validator":{
          "kind":"LimitLengthOnlyZenkaku",
          "pattern":[20]
        }
      },
    {
      "kind":"template",
      "template":"admin_revision_field",
      "args":{
        "order":"$(order)",
        "id_prefix":"enter",
        "title_prefix":"記載",
        "num":"$(num)"
      }
    },
    {
      "kind":"template",
      "template":"admin_revision_field",
      "args":{
        "order":"$(order)",
        "id_prefix":"delete",
        "title_prefix":"削除",
        "num":"$(num)"
      }
    }
  ]
  },
  "admin_revision_field":{
    "id":"mover_revision_$(id_prefix)_field_$(order)_$(num)",
    "kind":"field",
    "title":"通称$(title_prefix)",
    "fields":[
      {
        "kind": "template",
        "id": "mover_revision_$(id_prefix)_date_$(order)_$(num)",
        "title": "通称$(title_prefix)年月日",
        "csvqr":true,
        "template": "date_in_japanese_calendar",
        "context": {
          "eras": [
            {
              "name": "西暦",
              "value": "A.D.",
              "min": 1900,
              "max": 2025
            }
          ]
        }
      },
      {
        "id":"mover_revision_$(id_prefix)_address_field_$(order)_$(num)",
        "kind":"field",
        "title":"通称$(title_prefix)都道府県・市区町村漢字",
        "fields":[
          {
            "id": "mover_revision_$(id_prefix)_zipcode_$(order)_$(num)",
            "kind": "template",
            "title": "郵便番号",
            "template": "zipcode",
            "required": true
          },
          {
            "kind": "template",
            "id": "mover_revision_address_$(id_prefix)_$(order)_$(num)",
            "csvqr":true,
            "title": "都道府県・市区町村・町名",
            "template": "address2",
            "refer": "mover_revision_$(id_prefix)_zipcode_$(order)_$(num)-raw",
            "validator":"ZenkakuOnly"
          }
        ]
      },
      {
        "id": "mover_revision_$(id_prefix)_pref_kana_$(order)_$(num)",
        "kind": "text",
        "csvqr":true,
        "title":"通称$(title_prefix)都道府県カナ",
        "validator":"KatakanaOnly"
      },
      {
        "id": "mover_revision_$(id_prefix)_city_kana_$(order)_$(num)",
        "kind": "text",
        "csvqr":true,
        "title":"通称$(title_prefix)市区町村カナ",
        "validator":"KatakanaOnly"
      },
      {
        "id": "mover_revision_$(id_prefix)_city_add_kana_$(order)_$(num)",
        "kind": "text",
        "csvqr":true,
        "title":"通称$(title_prefix)市区町村補助欄カナ",
        "validator":"KatakanaOnly"
      }
    ]
  },
  "admin_additional_address_set":{
    "kind":"array",
    "items":[
      {
        "kind":"text",
        "id":"$(id_prefix)_jimeichoume_$(order)",
        "csvqr":true,
        "title": "字名丁目",
        "validator":"ZenkakuOnly"
      },
      {
        "kind":"text",
        "id":"$(id_prefix)_banch_$(order)",
        "csvqr":true,
        "title": "番地",
        "validator":"HankakuOnly"
      },
      {
        "kind":"text",
        "id":"$(id_prefix)_edaban_$(order)",
        "csvqr":true,
        "title": "枝番",
        "validator":"HankakuOnly"
      },
      {
        "kind":"text",
        "id":"$(id_prefix)_koedaban_$(order)",
        "csvqr":true,
        "title": "小枝番",
        "validator":"HankakuOnly"
      },
      {
        "kind":"text",
        "id":"$(id_prefix)_edaban3_$(order)",
        "csvqr":true,
        "title": "枝番3",
        "validator":"HankakuOnly"
      },
      {
        "kind":"text",
        "id":"$(id_prefix)_banchiedit_$(order)",
        "csvqr":true,
        "title": "番地編集",
        "validator":"ZenkakuOnly"
      }
    ]
  },
  "rewrite_mynuber":{
    "id":"rewrite_mynumber_$(order)",
    "title":"個人番号カード等書換",
    "kind":"select",
    "csvqr":true,
    "options":[
      {
        "label":"--",
        "value":""
      },
      {
        "label":"個人番号カード未提示・未書換",
        "value":"個カ未提示"
      },
      {
        "label":"個人番号カード提示・書換要",
        "value":"個カ提示有"
      },
      {
        "label":"個人番号カード未交付",
        "value":"個カ未交付"
      },
      {
        "label":"カード提示不要・書換不要",
        "value":"個カ提示不要"
      },
      {
        "label":"住基カード未提示",
        "value":"住カ提示無"
      },
      {
        "label":"住基カード提示",
        "value":"住カ提示有"
      }
    ]
  },
  "mover_admin":{
    "id":"admin_mover_$(order)",
    "title":"$(order)人目の異動者情報",
    "kind":"field",
    "foldable": true,
    "fields":[
      {
        "id":"is_need_newer_residents_code_$(order)",
        "title":"住民票コード新番付番要否",
        "csvqr":true,
        "kind":"select",
        "options": [
          {
            "label": "不要",
            "value": "不要"
          },
          {
            "label": "要",
            "value": "要"
          }
        ],
        "initialValue":"不要",
        "required": false
      },
      {
        "id":"residents_code_$(order)",
        "kind":"text",
        "csvqr":true,
        "title":"住民票コード",
        "validator":{
          "kind":"SpecifiedLengthDigitsOnlyHankakuSuji",
          "pattern":[11]
        }
      },
      {
        "id":"is_need_newer_mynumber_$(order)",
        "title":"個人番号新番付番要否",
        "kind":"select",
        "csvqr":true,
        "options": [
          {
            "label": "不要",
            "value": "不要"
          },
          {
            "label": "要",
            "value": "要"
          }
        ],
        "initialValue":"不要",
        "required": false
      },
      {
        "id":"mynumber_$(order)",
        "kind":"text",
        "csvqr":true,
        "title":"個人番号",
        "validator": {
          "kind": "SpecifiedLengthDigitsOnlyHankakuSuji",
          "pattern": [12]
        }
      },
      {
        "id":"atenabangou_$(order)",
        "kind":"text",
        "csvqr":true,
        "title":"宛名番号",
        "validator": {
          "kind": "SpecifiedLengthDigitsOnlyHankakuSuji",
          "pattern": [10]
        }
      },
      {
        "id": "admin_mover_kana_$(order)",
        "kind": "text",
        "csvqr":true,
        "title": "カナ氏名",
        "validator":{
          "kind":"LimitLengthOnlyZenkakuKatakana",
          "pattern":[100]
        },
        "required": true
      },
      {
        "id": "admin_mover_name_$(order)",
        "kind": "text",
        "title": "漢字氏名",
        "csvqr":true,
        "validator":{
          "kind":"LimitLengthOnlyZenkaku",
          "pattern":[100]
        }
      },
      {
        "kind": "template",
        "id": "admin_mover_birthdate_$(order)",
        "title": "生年月日",
        "csvqr":true,
        "template": "birthdate_universal_short"
      },
      {
        "kind": "radio",
        "title": "性別",
        "id": "admin_mover_gender_$(order)",
        "csvqr":true,
        "options": [
          {
            "label": "男",
            "value": "男"
          },
          {
            "label": "女",
            "value": "女"
          }
        ],
        "required": true
      },
      {
        "id": "admin_mover_old_kana_$(order)",
        "kind": "text",
        "csvqr":true,
        "title":"カナ旧氏・通称名〔現〕",
        "validator":{
          "kind":"LimitLengthOnlyZenkakuKatakana",
          "pattern":[20]
        }
      },
      {
        "id": "admin_mover_old_name_$(order)",
        "kind": "text",
        "csvqr":true,
        "title":"漢字旧氏・通称名〔現〕",
        "validator":{
          "kind":"LimitLengthOnlyZenkaku",
          "pattern":[20]
        }
      },
      {
        "kind":"template",
        "template":"admin_revision_field_wrap",
        "args":{
          "order":"$(order)",
          "num":"1"
        }
      },
      {
        "kind":"template",
        "template":"admin_revision_field_wrap",
        "args":{
          "order":"$(order)",
          "num":"2"
        },
        "condition":{
          "id":"number_of_oldname_field_$(order)",
          "value":[1,2,3,4]
        }
      },
      {
        "kind":"template",
        "template":"admin_revision_field_wrap",
        "args":{
          "order":"$(order)",
          "num":"3"
        },
        "condition":{
          "id":"number_of_oldname_field_$(order)",
          "value":[2,3,4]
        }
      },
      {
        "kind":"template",
        "template":"admin_revision_field_wrap",
        "args":{
          "order":"$(order)",
          "num":"4"
        },
        "condition":{
          "id":"number_of_oldname_field_$(order)",
          "value":[3,4]
        }
      },
      {
        "kind":"template",
        "template":"admin_revision_field_wrap",
        "args":{
          "order":"$(order)",
          "num":"5"
        },
        "condition":{
          "id":"number_of_oldname_field_$(order)",
          "value":[4]
        }
      },
      {
        "kind": "number",
        "id": "number_of_oldname_field_$(order)",
        "title": "通称記載・削除の入力フィールドを追加する。",
        "min": 0,
        "max": 4
      },
      {
        "id": "admin_mover_relation_$(order)",
        "kind": "text",
        "csvqr":true,
        "title": "続柄",
        "validator":"ZenkakuOnly"
      },
      {
        "id": "mover_relation_code_$(order)",
        "kind": "template",
        "template":"zokugara_code",
        "csvqr":true,
        "title": "続柄コード"
      },
      {
        "kind":"template",
        "template":"honseki_set",
        "args":{
          "order":"$(order)",
          "admin_prefix":"admin_",
          "address_description":"",
          "address_placeholder":"",
          "chouban_placeholder":""
        }
      },
      {
        "kind":"template",
        "template":"admin_additional_address_set",
        "args":{
          "order":"$(order)",
          "id_prefix":"admin_mover_honseki_address"
        }
      },
      {
        "kind":"text",
        "hidden":true,
        "id":"target_admin_mover_honseki_address_$(order)"
      },
      {
        "kind": "text",
        "id": "admin_honseki_name_$(order)",
        "csvqr":true,
        "title":"筆頭者",
        "validator":{
          "kind":"LimitLengthOnlyZenkaku",
          "pattern":[50]
        }
      },
      {
        "kind":"template",
        "template":"rewrite_mynuber",
        "args":{
          "order":"$(order)"
        }
      },
      {
        "id": "admin_has_card_$(order)",
        "kind": "radio",
        "title": "在留カード等有無",
        "options": [
          {
            "label": "在留カード、特別永住者証明書",
            "value": "有 "
          },
          {
            "label": "パスポート（在留カードの代わり）",
            "value": "有"
          },
          {
            "label": "なし",
            "value": "無"
          },
          {
            "label": "職権",
            "value": "職権"
          }
        ],
        "required": true
      },
      {
        "id": "admin_nationality_$(order)",
        "title": "国籍",
        "kind": "template",
        "csvqr":true,
        "template": "select_country"
      },
      {
        "id": "admin_mover_alphabet_$(order)",
        "kind": "text",
        "title": "英字氏名",
        "csvqr":true,
        "validator":{
          "kind":"LimitLengthOnlyZenkaku",
          "pattern":[100]
        }
      },
      {
        "id": "admin_qualification_$(order)",
        "title": "在留資格",
        "csvqr":true,
        "kind": "template",
        "template":"visa",
        "required": true
      },
      {
        "id": "admin_mover_stay_$(order)",
        "kind": "template",
        "template": "simple_year_month",
        "required": false,
        "csvqr":true,
        "title": "在留期間"
      },
      {
        "id": "admin_residence_type_$(order)",
        "kind": "template",
        "template":"hou_30_45",
        "title": "法第30条45規定区分",
        "csvqr":true,
        "required": true
      },
      {
        "id": "admin_mover_card_$(order)",
        "kind": "text",
        "title": "在留カード等番号",
        "csvqr":true,
        "min": 12,
        "max": 12,
        "validator": {
          "kind": "SpecifiedLengthDigitsOnlyHankakuEisuji",
          "pattern": [12]
        },
        "required": true
      },
      {
        "id": "admin_period_$(order)",
        "kind": "template",
        "title": "在留期間満了日",
        "csvqr":true,
        "template": "date_in_japanese_calendar",
        "required": true,
        "context": {
          "eras": [
            {
              "name": "西暦",
              "value": "R",
              "min": 2000,
              "max": 2100
            }
          ]
        }
      },
      {
        "id": "admin_mover_gakunen_$(order)",
        "title": "学年",
        "kind": "template",
        "template": "school_grade",
        "required": true
      },
      {
        "id": "admin_new_school_name_$(order)",
        "title": "学校名",
        "kind": "text"
      }
    ]
  }
};

export interface AnalogFormValueBindAttribute {
  bindType?: "value";
  questionPath: string;
  condition?: string;
  targetValue?: string;
  page?: number;
}

export interface AnalogFormCheckboxBindAttribute {
  bindType: "checkbox";
  questionPath: string;
  targetValue: string;
  page?: number;
}

export type AnalogFormBindAttribute = AnalogFormValueBindAttribute | AnalogFormCheckboxBindAttribute;

export const extractBindAttribute = (span: HTMLSpanElement): AnalogFormBindAttribute => {
  const condition = span.getAttribute("data-condition");
  const targetValue = span.getAttribute("data-target-value");
  const bindType = span.getAttribute("data-bind-type");
  const page = span.getAttribute("data-page");
  if (bindType === "checkbox") {
    return {
      bindType,
      questionPath: span.getAttribute("data-question-path"),
      targetValue: targetValue ? targetValue : undefined,
      page: page ? parseInt(page) : undefined
    };
  }
  return {
    questionPath: span.getAttribute("data-question-path"),
    condition: condition ? condition : undefined,
    targetValue: targetValue ? targetValue : undefined,
    page: page ? parseInt(page) : undefined
  };
}

export const isAnalogFormValueBindAttribute = (attribute: AnalogFormBindAttribute): attribute is AnalogFormValueBindAttribute => {
  return attribute.bindType === undefined || attribute.bindType === "value";
}

export const setBindAttribute = (span: HTMLSpanElement, definition: AnalogFormBindAttribute) => {
  span.setAttribute("data-question-path", definition.questionPath);
  if (isAnalogFormValueBindAttribute(definition)) {
    if (definition.condition) {
      span.setAttribute("data-condition", definition.condition);
    }
  } else {
    span.setAttribute("data-bind-type", definition.bindType);
  }
  if (definition.targetValue) {
    span.setAttribute("data-target-value", definition.targetValue);
  }
  if (definition.page) {
    span.setAttribute("data-page", String(definition.page));
  }
}